import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faFacebook, faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";

import Link from "next/link";

import "./Footer.scss";

const propTypes = {};

const Footer = (props) => {
    return (
        <footer className="footer">
            <div className="content-container">
                <div className="logo-section">
                    <Link href="/" as="/">
                        <a className="logo-link">
                            <img src="/static/logo_scribe.svg" className="scribe-icon" alt="Scribe logo" />
                            <span className="logo">Scribe.</span>
                        </a>
                    </Link>

                    <section className="links-container horizontal social-media-links-medium-screen">
                        <ul>
                            <li>
                                <a className="link icon" target="_blank" href="https://github.com/scribewiki">
                                    <FontAwesomeIcon icon={faGithub} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="link icon"
                                    target="_blank"
                                    href="https://www.twitter.com/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="link icon"
                                    target="_blank"
                                    href="https://www.facebook.com/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="link"
                                    target="_blank"
                                    href="https://linkedin.com/companies/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
                <div className="links-section">
                    <section className="links-container">
                        <h4 className="company-title">Scribe</h4>
                        <ul>
                            <li>
                                <Link href="/#features" as="/#features">
                                    <a className="link">Features</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/blog" as="/blog">
                                    <a className="link">Blog</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/about" as="/about">
                                    <a className="link">About</a>
                                </Link>
                            </li>
                        </ul>
                    </section>
                    <section className="links-container">
                        <h4>Resources</h4>
                        <ul>
                            <li>
                                <Link href="/about/terms-of-service" as="/about/terms-of-service">
                                    <a className="link">Terms of Service</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/about/privacy" as="/about/privacy">
                                    <a className="link">Privacy Policy</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/contact" as="/contact">
                                    <a className="link">Contact us</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/press" as="/press">
                                    <a className="link">Press</a>
                                </Link>
                            </li>
                        </ul>
                    </section>
                    <section className="links-container social-media-links">
                        <ul>
                            {/* <li>
                                <a className="link icon" target="_blank" href="https://github.com/scribewiki">
                                    <FontAwesomeIcon icon={faGithub} />
                                </a>
                            </li> */}
                            <li>
                                <a
                                    className="link icon"
                                    target="_blank"
                                    href="https://www.twitter.com/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="link icon"
                                    target="_blank"
                                    href="https://www.facebook.com/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="link icon"
                                    target="_blank"
                                    href="https://linkedin.com/companies/scribewiki"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = propTypes;

export default Footer;

// https://www.facebook.com/scribewiki
// https://www.twitter.com/scribewiki
// https://github.com/scribewiki
// htt
