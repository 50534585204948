import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import VisuallyHidden from "@reach/visually-hidden";
import Router, { useRouter } from "next/router";
import { SkipNavLink } from "@reach/skip-nav";

import Button from "@components/Button/Button";
import Portal from "@reach/portal";

import { useScrollTopThreshold } from "@hooks/window-hooks";
import * as gtag from "@modules/gtag";

import PropTypes from "prop-types";

import "./NavBar.scss";

const propTypes = {};

const NavBar = ({ show = true, mode = "all", theme, border = false }) => {
    const [showNavMenu, setShowNavMenu] = useState(false);
    const navBarRef = useRef();
    const menuTopPosRef = useRef(0);
    const router = useRouter();
    const isAtTop = useScrollTopThreshold(50);

    const showLinks = mode === "all";
    const showLogIn = mode === "all";
    const showRequestAccess = mode === "access";
    const showMenuButton = mode === "all";

    useEffect(() => {
        function handleClick(event) {
            if (!event.target.closest(".menu,.menu-icon-container")) {
                setShowNavMenu(false);
            }
        }

        document.addEventListener("click", handleClick);

        return () => document.removeEventListener("click", handleClick);
    }, []);

    function handleMenuToggle() {
        menuTopPosRef.current = window.scrollY + 8;
        setShowNavMenu(!showNavMenu);
    }

    function handleNavRequestAccess() {
        gtag.event({
            action: "click",
            category: "Landing Page",
            label: "Request Beta Access - Navigation",
        });

        if (router.pathname === "/") {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            setTimeout(() => document.querySelector("#request-access-email").focus(), 1000);
        } else {
            Router.push("/?request-access", `/?request-access`, { shallow: true });
        }
    }

    function handleLoginClick() {
        Router.push("/login/[[...slug]]", `/login`, { scroll: false, shallow: true });
    }

    return (
        <header
            className="lp-navbar"
            data-show={show}
            data-mode={mode}
            data-theme={theme}
            data-top={isAtTop}
            data-border={border}
        >
            <SkipNavLink />
            <div className="navbar-content" ref={navBarRef}>
                <Link href="/" as="/">
                    <a className="logo-link">
                        <img src="/static/logo_scribe.svg" className="scribe-icon" alt="Scribe logo" />
                        <span className="logo">Scribe.</span>
                    </a>
                </Link>

                {showLinks && (
                    <nav>
                        <ul className="navbar-links">
                            <li>
                                <Link href="/#features" as="/#features">
                                    <a className="navbar-link" data-active={router.pathname === "/"}>
                                        Features
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <Link href="/pricing" as="/pricing">
                                    <a className="navbar-link" data-active={router.pathname === "/pricing"}>
                                        Pricing
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <Link href="/blog" as="/blog">
                                    <a className="navbar-link" data-active={router.pathname === "/blog"}>
                                        Blog
                                    </a>
                                </Link>
                            </li>

                            <li>
                                <Link href="/about" as="/about">
                                    <a className="navbar-link" data-active={router.pathname === "/about"}>
                                        About
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                )}

                <div className="content__right">
                    {showLogIn && (
                        <div className="log-in-btn-container">
                            <Button
                                size="small"
                                variant={theme === "light" ? "secondary" : undefined}
                                onClick={handleLoginClick}
                            >
                                Log in
                            </Button>
                        </div>
                    )}

                    {showMenuButton && (
                        <button className="menu-icon-container" onClick={handleMenuToggle}>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    )}

                    {showRequestAccess && (
                        <div className="request-access-btn-container">
                            <Button size="small" variant="primary" onClick={handleNavRequestAccess}>
                                <span className="text-small">Request Access</span>
                                <span className="text">Request Beta Access</span>
                            </Button>
                        </div>
                    )}
                </div>

                <Portal>
                    <div
                        className="menu"
                        data-visible={showNavMenu}
                        tabIndex={-1}
                        style={{ top: menuTopPosRef.current }}
                    >
                        <button className="close-button" onClick={() => setShowNavMenu(false)}>
                            <VisuallyHidden>Close</VisuallyHidden>
                            <FontAwesomeIcon icon={faTimes} aria-hidden />
                        </button>

                        <nav>
                            <ul className="menu-links">
                                <li>
                                    <Link href="/#features" as="/#features">
                                        <a className="menu-link" data-active={router.pathname === "/"}>
                                            Features
                                        </a>
                                    </Link>
                                </li>

                                <li>
                                    <Link href="/pricing" as="/pricing">
                                        <a className="menu-link" data-active={router.pathname === "/pricing"}>
                                            Pricing
                                        </a>
                                    </Link>
                                </li>

                                <li>
                                    <Link href="/blog" as="/blog">
                                        <a className="menu-link" data-active={router.pathname === "/blog"}>
                                            Blog
                                        </a>
                                    </Link>
                                </li>

                                <li>
                                    <Link href="/about" as="/about">
                                        <a className="menu-link" data-active={router.pathname === "/about"}>
                                            About
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <div className="login-btn-container">
                            <Link href="/login/[[...slug]]" as="/login">
                                <a className="login-btn">Log in</a>
                            </Link>
                        </div>
                    </div>
                </Portal>
            </div>
        </header>
    );
};

NavBar.propTypes = propTypes;

export default NavBar;
