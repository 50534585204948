const DEFAULT_SEO = {
    title: "Scribe",
    description: "A collaborative knowledge base that can scale to any size",
    url: "https://scribe.wiki",
    openGraph: {
        type: "website",
        locale: "en_US",
        url: "https://scribe.wiki",
        title: "Scribe",
        description: "A collaborative knowledge base that can scale to any size",
        image: "https://scribe.wiki/static/logo_scribe_opengraph.jpg",
        site_name: "Scribe",
        imageWidth: "1200",
        imageHeight: "630",
    },
    twitter: {
        handle: "@ScribeWiki",
        cardType: "summary_medium_image",
        title: "Scribe",
        description: "A collaborative knowledge base that can scale to any size",
    },
};

function generateBlogJSONLD({
    title,
    description,
    url,
    imageUrl,
    datePublished,
    dateModified,
    authorGivenName,
    authorFamilyName,
}) {
    return {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": url,
        },
        headline: title,
        image: [imageUrl],
        datePublished,
        dateModified,
        description,
        author: {
            "@type": "Person",
            givenName: authorGivenName,
            familyName: authorFamilyName,
            worksFor: {
                "@type": "Organization",
                name: "Scribe",
            },
        },
        publisher: {
            "@type": "Organization",
            name: "Scribe",
            email: "hello@scribe.wiki",
            slogan: "A collaborative knowledge base that can scale to any size",
            logo: {
                "@type": "ImageObject",
                url: "https://scribe.wiki/static/logo_scribe.svg",
            },
        },
    };
}

function generateTabletJSONLD() {}

function generateCorporationJSONLD() {
    return {
        "@context": "http://schema.org",
        "@type": "Corporation",
        name: "Scribe",
        url: "https://scribe.wiki",
        logo: "https://scribe.wiki/static/logo_scribe.svg",
        sameAs: ["https://twitter.com/ScribeWiki"],
        contactPoint: {
            url: "https://scribe.wiki/contact",
            email: "hello@scribe.wiki",
            contactType: "customer support",
        },
    };
}

const generateJSONLD = (type) => {
    switch (type) {
        case "blog":
            return generateBlogJSONLD;
        case "tablet":
            return generateTabletJSONLD;
        case "corporation":
            return generateCorporationJSONLD;
        default:
            throw new Error("The type of JSONLD you are after doesn't exist");
    }
};

export { generateJSONLD, DEFAULT_SEO };
